const cs_msg = {
    placeholder: "Zadejte IČO nebo název firmy",
    searching: "Hledáme odpovídající firmy",
    nothingFound: "Pro zadanou hodnotu nebyla nalezena žádná firma.",
    startWritting: "Začněte psát",
    organizationId: "IČO",
    branch: "Pobočka",
    international: {
        waiting: "Chvilku strpení, musíme vše prověřit v datech z více než 190 zemí světa",
        heading: "Procházíme",
        companies: "firem",
        managers: "manažerů",
        owners: "vlastníků",
        shareholders: "podílníků",
        searching: "Vteřinku prosím, prohledáváme databázi s více než 110M firem z 190+ zemí světa.",
        downService: "Vyhledávání právě není k dispozici, prosím zkuste to za chvíli.",
        errors: {
            undefined: "Data v tuto chvíli nejsou dostupná, prosíme zkuste to za chvíli",
            noCredit: "Nemáte žádný volný kredit",
            noUser: "Nejste přihlášen/a, nebo nejste v žádné organizaci",
            moodys: "Data v tuto chvíli nejsou dostupná, prosíme zkuste to za chvíli",
            noCreditUsage: "Nemáte oprávnění pro využití kreditu"
        }
    },
    leadForm: {
        name: "Jméno",
        phone: "Telefon",
        email: "E-mail",
        send: "Ozvěte se mi",
        thankyou: "Děkujeme",
        week: "Ještě dnes se vám ozveme, vše vysvětlíme a&nbsp;případně i&nbsp;rovnou zprovozníme.",
        weekend: "Užijte si zbytek víkendu. V pondělí se vám ozveme, vše vysvětlíme a&nbsp;případně i&nbsp;rovnou zprovozníme."
    },
    changeType: {
        cz: "Vyhledáváte pouze české firmy. Zahraniční můžete hledat",
        global: "Zajímají vás jen v české firmy? Hledejte",
        didntFound: "Nenašli jste to co jste hledali? Zkuste vyhledávání zahraničních firem",
        here: "zde"
    },
    types: {
        cz: "České firmy",
        global: "Zahraniční firmy",
        person: "Fyzické osoby"
    },
    autoscreening: {
        filter: {
            placeholder: "Název firmy nebo IČO"
        },
        subject: "Firma",
        organization_id: "IČO",
        state: "Stav",
        lastChange: "Poslední změna",
        page: "Stránka { page } z { maxPage }"
    },
    person: {
        errors: {
            emptyName: "Jméno není vyplněno.",
            notFullName: "Jméno není celé ve formátu <KrestniJmeno><Mezera><Přijmení>.",
            shortFirstName: "Křestní jméno je krátké.",
            shortSecondName: "Příjmení je krátké.",
            emptyBirthdate: "Datum narození není vyplněno",
            emptyCountries: "Žádná země nebyla vybrána"
        },
        countriesHelp: "Zemí můžete vybrat více. Např. v případě různého místa narození a trvalého pobytu, nebo při změně občanství je lepší zadat všechny relevatní země.",
        birthdateHelp: "Pokud nevíte přesné datum, zadejte nejbližší možné. V případě znalosti pouze měsíce a roku zadejte první den v měsíci, v případě znalosti pouze roku zdajte 1. ledna.",
        nameHelp: "Celé jméno fyzické osoby",
        verify: "Prověřit",
        countries: "Země",
        birthdate: "Datum narození",
        name: "Jméno osoby"
    },
    utils: {
        select: "Vybrat",
        selected: "Vybráno",
        deselect: "Odstranit",
        more: "+ {count} dalších | + {count} další | + {count} další | + {count} dalších"
    }
}


export default cs_msg;