<template>
    <div class="text-center mt-1" v-if="sent">
        <h3>{{ $t("leadForm.thankyou") }} 🙏</h3>
        <p v-if="weekday < 6" v-html="$t('leadForm.week')"></p>
        <p v-else v-html="$t('leadForm.weekend')"></p>
    </div>
    <form v-else @submit.prevent="sendForm">
        <div class="field">
            <label for="name">{{ $t("leadForm.name") }}</label>
            <input type="text" v-model="name" class="form-control">
        </div>
        <div class="field">
            <label for="phone">{{ $t("leadForm.phone") }}</label>
            <input type="text" v-model="phone" class="form-control">
        </div>
        <div class="field">
            <label for="email">{{ $t("leadForm.email") }} <span class="required">*</span></label>
            <input type="email" v-model="email" class="form-control" required>
        </div>
        <button id="button_send_order" type="submit" class="btn btn-primary">{{ $t("leadForm.send") }}</button>
    </form>
</template>

<script>
import axios from "axios";

const api = axios.create({
    baseURL: "/api/",
    withCredentials: true,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken"
});

export default {
    data: function () {
        return {
            sent: false,
            name: null,
            phone: null,
            email: null,
        }
    },
    computed: {
        disabled: function () {
            return !this.email
        },
        weekday: function () {
            return new Date().getDay()
        }
    },
    methods: {
        sendForm: function () {
            if (this.disabled) {
                return
            }
            api.post("lead_form", {
                name: this.name,
                phone: this.phone,
                email: this.email
            })
                .then(r => {
                    if (r.data.sent) {
                        this.sent = r.data.sent;
                    }
                })
        }
    }
}
</script>

<style></style>